import axios from "axios";
import classNames from "classnames/bind";
import { Field, Form, Formik } from "formik";
import React, { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { usePaginatedQuery } from "react-query";
import { interpolate, isNumber, vowelReduce } from "./utils.js";

import styles from "./Search.module.scss";

// classNames function for CSS modules:
const cx = classNames.bind(styles);

const LIMIT = 500;

const ttcontent = {
  queryInput: "Required: at least 1 character",
  strict: "Match whole word and accents",
  partial: "Match partial word and accents",
  relaxed: "Match partial word and ignore accents"
};


/**
 * react-query fetch function:
 */
const fetchResults = async (key, { queryValues, page }) => {
  try {
    let {
      queryInput,
      searchInPos,
      searchInHeadword,
      searchInWordforms,
      searchInEntry,
      strictMode
    } = queryValues;
    queryInput = (queryInput || "").trim();
    if (!queryInput) return;

    let fields = [];
    if (searchInPos) fields.push("POS");
    if (searchInHeadword) fields.push("headword");
    if (searchInWordforms) fields.push("wordforms");
    if (searchInEntry) fields.push("entryPlain");

    let params = [];
    if (strictMode) params.push(strictMode);
    if (fields.length) params.push(`fields=${fields}`);
    if (isNumber(page)) {
      params.push([`limit=${LIMIT}`]);
      params.push(`page=${page}`);
    }
    params = params.join("&");
    const fullQuery = `/query?s=${encodeURIComponent(queryInput)}&${params}`;
    // console.log("fullQuery=", fullQuery);

    const result = await axios(fullQuery);
    if (result.status >= 400) {
      return result.statusText;
    } else {
      return { payload: result.data };
    }
  } catch (axiosError) {
    if (axiosError.response) {
      console.error("axios error:", axiosError.response);
      return {
        error: axiosError.response.data.error,
        status: axiosError.response.status,
        statusText: axiosError.response.statusText
      };
    } else if (axiosError.request) {
      console.error("axios no response:", axiosError.request);
    } else {
      console.error("axios other error:", axiosError.message);
    }
  }
};

const queryError = err => {
  if (err.validation) {
    return err.validation.map(e => e.message);
  } else return JSON.stringify(err);
};

/**
 * The form component and results list.
 */
const Search = props => {
  const [formValues, setFormValues] = useState(0);
  const [page, setPage] = useState(0);
  const [searchOptionsHidden, setSearchOptionsHidden] = useState(false);
  const queryInputRef = useRef(null);

  // Keyboard shortcuts: focus input field on pressing Esc
  useEffect(
    () => {
      const onKeyUp = ({ key, shiftKey, ctrlKey, altKey }) => {
        if (queryInputRef && queryInputRef.current) {
          if(key === "Escape"){
            queryInputRef.current.focus();
          }
        }
      };

      document.addEventListener("keyup", onKeyUp);
      return () => {
        window.removeEventListener("keyup", onKeyUp);
      };
    },
    [queryInputRef]
  );


  const queryKey = ["tgedData", { queryValues: formValues, page }];

  const {
    isLoading,
    isFetching,
    error,
    resolvedData: searchResults
  } = usePaginatedQuery(queryKey, fetchResults, {
    staleTime: 60 * 1000,
    onSuccess: successData => {
      const res = ((successData || {}).payload || {}).results || {};
      if (isNumber((res || {}).page)) {
        setPage(res.page);
      }
    }
  });


  const submitForm = (values, { setSubmitting }) => {
    setSearchOptionsHidden(true);
    setFormValues(values);
    setPage(0);
  };

  const handlePageChange = e => setPage(e.target.dataset.cursor);

  if (error) console.error("An error has occurred: " + error.message);
  // if (isLoading) console.log("Loading...");
  // if (((searchResults || {}).payload || {}).results) {
  //   console.log("Search Results:", (searchResults || {}).payload);
  // }

  const { page: currentPage, prev, next, totalSize } =
    ((searchResults || {}).payload || {}).results || {};

  return (
    <div className={styles.outer}>
      {/* Errors */}
      {error && (
        <div className={styles.fetchError}>RQ Error: {error.message}</div>
      )}
      {(searchResults || {}).error && (
        <div className={styles.fetchError}>
          {queryError(searchResults.error)}
        </div>
      )}
      {(searchResults || {}).statusText && (
        <div className={styles.fetchError}>
          {(searchResults || {}).statusText}
        </div>
      )}

      {/* Input form */}
      <Formik
        initialValues={{
          queryInput: "",
          searchInHeadword: true,
          strictMode: "relaxed",
          searchInWordforms: true,
          searchInEntry: true,
          searchInPos: false
        }}
        validate={values => {
          const errors = {};
          if (!values.queryInput || values.queryInput.length < 1) {
            errors.queryInput = ttcontent["queryInput"];
          }
          return errors;
        }}
        onSubmit={submitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Form>
            <div className={styles.inputBlock}>
              <div />
              <Tippy
                content={errors.queryInput}
                visible={!!errors.queryInput}
                placement="bottom"
              >
                <input
                  ref={queryInputRef}
                  className={cx({
                    input: true,
                    inputError: !!errors.queryInput
                  })}
                  type="text"
                  name="queryInput"
                  placeholder="Enter (parts of) a Gaelic word"
                  disabled={isFetching}
                  onBlur={e => {
                    handleBlur(e);
                  }}
                  onChange={handleChange}
                  onFocus={() => setSearchOptionsHidden(false)}
                />
              </Tippy>

              <button
                type="submit"
                disabled={isFetching}
                className={cx({
                  queryButton: true,
                  pulse: isFetching || isLoading
                })}
              >
                Query!
              </button>
              <div />
            </div>

            <div
              className={cx({
                checkboxBlock: true,
                isHidden: searchOptionsHidden
              })}
            >
              <div />
              <div className={styles.checkboxes}>
                <div className={styles.checkboxGroup}>
                  <div> Search in:</div>

                  <input
                    type="checkbox"
                    name="searchInHeadword"
                    id="searchInHeadword"
                    checked={values.searchInHeadword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="searchInHeadword">Headword</label>

                  <input
                    type="checkbox"
                    name="searchInWordforms"
                    id="searchInWordforms"
                    checked={values.searchInWordforms}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="searchInWordforms">Wordforms</label>

                  <input
                    type="checkbox"
                    name="searchInEntry"
                    id="searchInEntry"
                    checked={values.searchInEntry}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="searchInEntry">Main entry</label>

                  <input
                    type="checkbox"
                    name="searchInPos"
                    id="searchInPos"
                    checked={values.searchInPos}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="searchInPos">Grammar</label>
                </div>

                <div className={styles.checkboxGroup}>
                  <div>Matching mode:</div>
                  <Tippy content={ttcontent["strict"]} placement="bottom">
                    <label className={styles.nowrap}>
                      <Field type="radio" name="strictMode" value="strict" />
                      Strict
                    </label>
                  </Tippy>
                  <Tippy content={ttcontent["partial"]} placement="bottom">
                    <label className={styles.nowrap}>
                      <Field type="radio" name="strictMode" value="partial" />
                      Partial
                    </label>
                  </Tippy>
                  <Tippy content={ttcontent["partial"]} placement="bottom">
                    <label className={styles.nowrap}>
                      <Field type="radio" name="strictMode" value="relaxed" />
                      Relaxed
                    </label>
                  </Tippy>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* Results */}
      {(searchResults || {}).payload ? (
        <>
          {/* paging */}
          <div className={styles.resultsContainer}>
            <div className={styles.resultsCount}>{`${totalSize} results`}</div>
            &emsp;
            {(((searchResults || {}).payload || {}).results || {}).totalSize >
              0 && (
              <div className={styles.paging}>
                {isNumber(currentPage) && (
                  <>
                    &emsp;
                    <button
                      className={styles.pagingButton}
                      data-cursor={prev}
                      disabled={currentPage === 0}
                      onClick={handlePageChange}
                    >
                      &ensp;&lt;&ensp;
                    </button>
                  </>
                )}
                &ensp;
                {isNumber(currentPage) && (
                  <>
                    {currentPage}&ndash;{next || totalSize}
                  </>
                )}
                &ensp;
                <button
                  className={styles.pagingButton}
                  data-cursor={next}
                  disabled={!next}
                  onClick={handlePageChange}
                >
                  &ensp;&gt;&ensp;
                </button>
              </div>
            )}
                {/*isFetching ? <>&emsp;Fetching&hellip;</> : ""*/}
          </div>

          {/* list */}
          <div className={styles.resultList}>
            {searchResults.payload.results.result.map(r => (
              <ResultEntry
                key={r.id}
                r={r}
                searchTerm={formValues.queryInput}
              />
            ))}
          </div>
        </>
      ) : isFetching ? (
        "Loading..."
      ) : (
        "Nothing to show here."
      )}
    </div>
  );
};

const ResultEntry = ({ r, searchTerm }) => (
  <div className={styles.result}>
    <div className={styles.resultHeadwordContainer}>
      <div className={styles.resultHeadword}>
        {interpolate(r.headword.replace(searchTerm, "$1"), {
          $1: <span className={styles.searchTermInResults}>{searchTerm}</span>
        })}
      </div>
      <div className={styles.resultGrammar}>{r.POS}</div>
      <div className={styles.resultPage}>
        <a
          href={`${process.env.PUBLIC_URL}/out/${vowelReduce(r.headword[0])}/${r.page +
            49}.extracted.html`}
          target="_blank"
          rel="noopener noreferrer"
        >
          pg. {r.page}
        </a>
      </div>
    </div>
    <div className={styles.resultWordforms}>
      <Wordforms abbrv="gen" wordforms={r.genitives} />
      <Wordforms abbrv="pl" wordforms={r.plurals} />
    </div>
    <div className={styles.resultEntry}>
      <div className={styles.resultEntryPad} />
      <ResultMain entry={r.entry} searchTerm={searchTerm} />
    </div>
  </div>
);

/**
 * Recursively convert JSON structure to JSX:
 * accepts argument object of shape:
 * {
 *   text: <string>, // optional, leaf text
 *   tag: <string>, // optional, "i" or "b", expects children to be present that contain more tags or leaf text
 *   children: [<tag>], // optional
 * }
 */
const Tag = ({ tag = {}, searchTerm }) =>
  tag.children
    ? tag.children.map((c, i) => {
        if (c.text)
          return <Text key={i} text={c.text} searchTerm={searchTerm} />;
        else if (c.tag === "i")
          return (
            <React.Fragment key={i}>
              {" "}
              <i>
                <Tag tag={c} searchTerm={searchTerm} />
              </i>{" "}
            </React.Fragment>
          );
        else if (c.tag === "b")
          return (
            <React.Fragment key={i}>
              {" "}
              <b>
                <Tag tag={c} searchTerm={searchTerm} />
              </b>{" "}
            </React.Fragment>
          );
        else
          return (
            <React.Fragment key={i}>
              {" "}
              <Tag tag={c} searchTerm={searchTerm} />{" "}
            </React.Fragment>
          );
      })
    : null;

const Text = ({ text, searchTerm }) => {
  // const text1 = text
  //   .replace(/□/g, "□ ")
  const out = interpolate(
    text
      .replace(searchTerm, "$1")
      .replace(/□/g, "□ ")
      .replace(/■/, "$2"),
    {
      $1: <span className={styles.searchTermInResults}>{searchTerm}</span>,
      $2: (
        <>
          <br /> &emsp;
        </>
      )
    }
  );
  return out;
};

const ResultMain = ({ entry, searchTerm }) => (
  <>
    <div className={styles.resultEntryMain}>
      <div className={styles.resultEntryPad}> </div>
      <div>
        {(entry || {}).children && <Tag tag={entry} searchTerm={searchTerm} />}
      </div>
    </div>
  </>
);

const Wordforms = ({ abbrv, wordforms }) =>
  wordforms ? (
    <span className={styles.resultWordforms}>
      <span className={styles.grammarAbbrv}>{abbrv}</span>&ensp;
      {wordforms.map(d => (
        <React.Fragment key={d}>
          <span>{d}</span>&ensp;
        </React.Fragment>
      ))}
      &emsp;
    </span>
  ) : null;

export default Search;
