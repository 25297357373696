import React from "react";

/**
 * Wraps text with JSX.
 * For each positional parameter in "text" in the form of "$1", "$2", ...,
 * replace the "$1" with something else.
 * const str = 'Put a tag around me'.replace("tag", "$1");
  interpolate(str, {
    $1: <span className={styles.mystr}>tag</span>
  })
  Result: Put a <span className={"highlight"}>tag</span> around me.
 */
const interpolate = (text, values) => {
  const pattern = /(\$[0-9]+)/g;
  const matches = text.match(pattern);
  const parts = text.split(pattern);

  if (!matches) {
    return text;
  }

  return parts.map((part, index) => (
    <React.Fragment key={part + index}>
      {matches.includes(part) ? values[part] : part}
    </React.Fragment>
  ));
};

/**
 * Checks "value" if it is numeric.
 * Returns true for "9", 9, "-3", "0", 0, 1e4, 3.3
 * but false for "", undefined, null, "a", NaN, ""
 */
const isNumber = value =>
  value !== "" &&
  value !== null &&
  typeof +value === "number" &&
  isFinite(+value);


const vowels = {
  a: "a",
  à: "a",
  á: "a",
  e: "e",
  è: "e",
  é: "e",
  i: "i",
  ì: "i",
  í: "i",
  o: "o",
  ò: "o",
  Ó: "o",
  u: "u",
  ù: "u",
  ú: "u"
};
// turns accented vowel to its basic form, lower cased
const vowelReduce = v => vowels[(v||"").toLocaleLowerCase()] || v;

export  {
  interpolate,
  isNumber,
  vowelReduce
};
