import React from "react";
import "./App.css";
import Search from "./Search.js";

function App() {
  return (
    <div className="App">
      <div />
      <div>
        <header className="App-header">The Gaelic English Dictionary</header>
        <Search />
      </div>
      <div />
    </div>
  );
}

export default App;
